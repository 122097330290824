/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import LocomotiveScroll from "locomotive-scroll"
import Seo from "./seo"
import Footer from "./footer"
import { ScrollToTop } from "./scroll-to-top"
import classNames from "classnames"
import { useAppContext } from "../context/AppContext"

interface Props {
  children: JSX.Element[] | JSX.Element
  hasBackground?: boolean
  className?: string
}

const Layout = ({ children, hasBackground = true, className }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const scrollRef = useRef<HTMLDivElement>(null)
  const [scrollY, setScrollY] = useState(0)
  const [locomotiveScroll, setLocomotiveScroll] = useState<LocomotiveScroll | null>(null)
  const [showBrandsMenu, setShowBrandsMenu] = useState<string | undefined>(undefined)

  const { isOpenModalTY } = useAppContext()

  useEffect(() => {
    if (!scrollRef) return

    const scrollElement = scrollRef?.current

    if (!scrollElement) return

    const scroll = new LocomotiveScroll({
      el: scrollElement,
      smooth: true,
      multiplier: 1.3,
    })

    setLocomotiveScroll(scroll)

    scroll.on("scroll", (args: any) => {
      const { scroll } = args
      setScrollY(scroll.y)
    })

    scroll.on("call", (section: any, status: any) => {
      if ((section === "brands-logo" || section === "we-are") && status === "enter") {
        setShowBrandsMenu(undefined)
        return
      }

      if (section === "hyperice" && status === "enter") {
        setShowBrandsMenu("hyperice")
        return
      }

      if (section === "tidl" && status === "enter") {
        setShowBrandsMenu("tidl")
        return
      }

      if (section === "whoop" && status === "enter") {
        setShowBrandsMenu("whoop")
        return
      }
    })

    // update scroll once images slider rendered
    const timer = setTimeout(() => {
      scroll.update()
    }, 500)

    return () => {
      scroll.destroy()
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    if (!locomotiveScroll) return

    if (isOpenModalTY) {
      locomotiveScroll?.stop()
    } else {
      locomotiveScroll?.start()
    }

    return () => {
      locomotiveScroll?.start()
    }
  }, [isOpenModalTY])

  return (
    <>
      <Seo title={data.site.siteMetadata?.title || `Title`} />
      <div data-scroll-container className={classNames("data-scroll-container", className)} ref={scrollRef}>
        <Header scrollY={scrollY} hasBackground={hasBackground} showBrandsMenu={showBrandsMenu} />
        <main className="overflow-hidden">{children}</main>
        <Footer />
        <ScrollToTop
          scrollY={scrollY}
          locomotiveScroll={locomotiveScroll}
          handleScrollY={(value: number) => {
            setScrollY(value)
          }}
        />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
