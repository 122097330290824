import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { HiArrowLongRight } from "react-icons/hi2"
import LogoFull from "../images/logo-full.svg"
import LinkedInLogo from "../images/linkedin.svg"
import FacebookLogo from "../images/facebook.svg"
import InstagramLogo from "../images/instagram.svg"

const Footer = () => {
  const year = new Date().getFullYear()

  return (
    <footer data-scroll-section className="bg-black overflow-hidden">
      <div className="container mx-auto flex flex-col gap-12 py-12">
        <LogoFull />

        <div className="flex flex-col md:flex-row gap-12 md:gap-8">
          <div className="flex flex-col w-full md:w-1/2 justify-between">
            <div className="text-grey-4 font-caslon text-4xl md:text-5xl font-medium">Unapologetically Better</div>
            <div className="hidden md:block text-white font-helvetica text-base font-normal">
              &copy; {year} TMBP The Move Better Project
            </div>
          </div>
          <div className="flex flex-col w-full md:w-1/2">
            <div className="space-y-4 mb-8">
              <a
                target="_blank"
                href="https://tmbp-apparel.netlify.app/"
                className="flex items-center gap-3 font-caslon text-2xl font-medium text-mono hover:text-grey-3 group group-hover:text-grey-3"
              >
                Custom Apparel
                <HiArrowLongRight size={32} className="group-hover:translate-x-2 transition-all duration-500" />
              </a>
              <div
                onClick={() => window.location.reload()}
                className="flex items-center gap-3 font-caslon text-2xl font-medium text-mono hover:text-grey-3 group group-hover:text-grey-3 cursor-pointer"
              >
                TMBP Distributors
                <HiArrowLongRight size={32} className="group-hover:translate-x-2 transition-all duration-500" />
              </div>
              <Link
                to="/"
                className="flex items-center gap-3 font-caslon text-2xl font-medium text-mono hover:text-grey-3 group group-hover:text-grey-3"
              >
                Partner Apparel Shop
                <HiArrowLongRight size={32} className="group-hover:translate-x-2 transition-all duration-500" />
              </Link>
            </div>

            <div className="flex gap-3 md:mb-0">
              <div className="font-helvetica text-lg font-normal text-grey-3">or follow us on</div>
              <a
                target="_blank"
                href="https://www.facebook.com/themovebetterproject"
                aria-label="Facebook"
                className="group"
              >
                <FacebookLogo className="text-white group-hover:text-grey-3  transition-all duration-300" />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/the-move-better-project/"
                aria-label="LinkedIn"
                className="group"
              >
                <LinkedInLogo className="text-white group-hover:text-grey-3 transition-all duration-300" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/themovebetterproject/"
                aria-label="Instagram"
                className="group"
              >
                <InstagramLogo className="text-white group-hover:text-grey-3 transition-all duration-300" />
              </a>
            </div>
          </div>
        </div>

        <div className="lg:hidden flex flex-col relative mb-8">
          <span className="font-helvetica text-base font-normal text-white">
            &copy; {year} TMBP The Move Better Project
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
