import React from "react"
import { HiArrowUp } from "react-icons/hi2"
import classNames from "classnames"

interface ScrollToTopProps {
  scrollY: number
  locomotiveScroll: LocomotiveScroll | null
  handleScrollY: (value: number) => void
  isModalOpen?: boolean
}

export const ScrollToTop: React.FC<ScrollToTopProps> = ({
  scrollY,
  locomotiveScroll,
  handleScrollY,
  isModalOpen = false,
}) => {
  const scrollToTop = () => {
    if (locomotiveScroll) {
      locomotiveScroll.scrollTo(0, {
        duration: 500,
        easing: [0.25, 0.0, 0.35, 1.0],
        callback: () => {
          handleScrollY(0)
        },
      })
    }
  }

  const isScrollDown = scrollY > 0

  return (
    <button
      className={classNames(
        "flex justify-center items-center fixed z-90 w-12 h-12 p-3 transition-all duration-300 ease-in-out bg-white rounded-full cursor-pointer sm:p-3 sm:w-12 sm:h-12 bottom-4 right-4 shadow-md border border-white",
        {
          "opacity-100": isScrollDown && !isModalOpen,
          "opacity-0": !(isScrollDown && !isModalOpen),
        }
      )}
      onClick={scrollToTop}
    >
      <HiArrowUp size={24} className="text-grey-3 hover:text-grey-5" />
    </button>
  )
}
