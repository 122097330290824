import { useLocation } from "@reach/router"
import classNames from "classnames"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { FC, ReactNode, useMemo, useState } from "react"
import { HiBars2, HiOutlineXMark } from "react-icons/hi2"
import LogoFullBlack from "../images/logo-full-black.svg"
import LogoFull from "../images/logo-full.svg"
import Logo from "../images/logo.svg"
import HeaderMenu from "./header-menu"

export type NavLinkProps = {
  to: string
  children: ReactNode
}

interface Props {
  showBrandsMenu?: string
  scrollY: number
  hasBackground: boolean
}

const Header: FC<Props> = ({ showBrandsMenu, scrollY, hasBackground }) => {
  const [menuShown, setMenuShown] = useState<boolean>(false)
  const isSticky = useMemo(() => scrollY > 0, [scrollY])
  const location = useLocation()

  const pathname = useMemo(() => location.pathname, [location])

  const toggleMenu = () => {
    setMenuShown(!menuShown)
  }

  const NavLink: React.FC<NavLinkProps> = ({ to, children }) => (
    <Link to={to} className="relative group">
      {children}
      <span
        className={classNames(
          "absolute left-0 right-0 bottom-0 h-0.5 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out",
          {
            "bg-white": hasBackground || isSticky,
            "bg-black": !hasBackground && !isSticky,
            "scale-x-100": pathname === to,
          }
        )}
      ></span>
    </Link>
  )

  return (
    <>
      <div
        className={classNames(
          "fixed top-0 left-0 right-0 z-100 transition-all duration-300 py-4 lg:py-3 overflow-hidden",
          {
            "bg-black": isSticky || menuShown || !hasBackground,
            "bg-transparent": !(isSticky || menuShown),
          }
        )}
      >
        <nav className="container mx-auto flex justify-between items-center">
          {isSticky || menuShown ? (
            <Link to="/">
              <Logo className="text-white" />
            </Link>
          ) : (
            <Link to="/">{hasBackground ? <LogoFull /> : <LogoFullBlack />}</Link>
          )}

          <div className="block lg:hidden nav-toggler-container">
            <button className="nav-toggler" onClick={toggleMenu}>
              <HiOutlineXMark
                size={40}
                className={classNames("icon x-icon", {
                  show: menuShown,
                  hide: !menuShown,
                  "text-white": hasBackground || menuShown,
                  "text-black": !hasBackground,
                })}
              />
              <HiBars2
                size={40}
                className={classNames("icon x-icon", {
                  show: !menuShown,
                  hide: menuShown,
                  "text-white": hasBackground || isSticky,
                  "text-black": !hasBackground,
                })}
              />
            </button>
          </div>

          <div
            className={classNames("hidden lg:flex items-center space-x-8  text-base font-medium font-helvetica", {
              "text-black": !hasBackground,
              "text-white": hasBackground || isSticky,
            })}
          >
            <NavLink to="/">Brands</NavLink>
            <NavLink to="/channels/">Channels</NavLink>
            <NavLink to="/about/">About</NavLink>
            <NavLink to="/contact/">Contact Us</NavLink>
          </div>
        </nav>
      </div>
      <div
        className={classNames("fixed top-16 left-0 right-0 z-100 bg-mono h-16 items-center justify-center", {
          hidden: !showBrandsMenu,
          "hidden lg:flex": showBrandsMenu,
        })}
      >
        <a
          className={classNames("scale-50 transition-all", {
            "opacity-100": showBrandsMenu === "hyperice",
            "opacity-75": showBrandsMenu !== "hyperice",
          })}
          data-scroll-to
          href="#hyperice"
        >
          <StaticImage src="../../images/brands-hyper.png" alt="hyper" quality={100} placeholder="none" />
        </a>

        <a
          className={classNames("scale-50 transition-all", {
            "opacity-100": showBrandsMenu === "tidl",
            "opacity-75": showBrandsMenu !== "tidl",
          })}
          data-scroll-to
          href="#tidl"
        >
          <StaticImage src="../../images/brands-tidl.png" quality={100} alt="tidl" placeholder="none" />
        </a>

        <a
          className={classNames("scale-50 transition-all", {
            "opacity-100": showBrandsMenu === "whoop",
            "opacity-75": showBrandsMenu !== "whoop",
          })}
          data-scroll-to
          href="#whoop"
        >
          <StaticImage src="../../images/brands-whoop.png" quality={100} alt="whoop" placeholder="none" />
        </a>
      </div>

      <HeaderMenu isOpen={menuShown} pathname={pathname} />
    </>
  )
}

export default Header
