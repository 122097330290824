import { Link } from "gatsby"
import React, { FC, useMemo } from "react"
import { NavLinkProps } from "./header"
import classNames from "classnames"
import { useLocation } from "@reach/router"

interface Props {
  isOpen: boolean
  pathname: string
}

const HeaderMenu: FC<Props> = ({ isOpen }) => {
  const location = useLocation()

  const pathname = useMemo(() => location.pathname, [location])

  const NavLink: React.FC<NavLinkProps> = ({ to, children }) => (
    <Link to={to} className="relative group w-full inline-block text-center pb-3">
      {children}
      <span
        className={classNames(
          "absolute left-4 right-4 bottom-0 h-[1px] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out bg-white",
          {
            "scale-x-100": pathname === to,
          }
        )}
      ></span>
    </Link>
  )

  return (
    <section
      className={classNames(
        "fixed top-0 left-0 right-0 bg-black z-90 transition-all duration-500 ease-out h-auto pb-8 overflow-hidden",
        {
          "opacity-100": isOpen,
          "opacity-0 -z-10": !isOpen,
        }
      )}
    >
      <div className="flex flex-col items-center text-white text-base font-medium font-helvetica space-y-8 mt-24">
        <NavLink to="/">Brands</NavLink>
        <NavLink to="/channels/">Channels</NavLink>
        <NavLink to="/about/">About</NavLink>
        <NavLink to="/contact/">Contact Us</NavLink>
      </div>
    </section>
  )
}

export default HeaderMenu
